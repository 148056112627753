export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तनों को सुरक्षित करें"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचत…"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीछे"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आपको यकीन है?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिलिपि आईडी"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["आज ", _interpolate(_list(0)), " पर"])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["कल ", _interpolate(_list(0)), " पर"])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके पास इस चैनल में बात करने की अनुमति नहीं है।"])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जुड़े नहीं हैं"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संदेश संलग्न करें"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना संदेश टाइप करें"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस नीचे की ओर"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए संदेश"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संकुचित करें"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपलोड करने के लिए"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल देखें"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिकाएँ संपादित करें"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लात मारना"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी यूजर: टैग"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उद्धरण"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादन करना"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पढ़े हुए का चिह्न"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर सेटिंग्स"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर छोड़ दें"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूक चैनल"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनम्यूट चैनल"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिसूचना"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमोजी का प्रबंधन करें"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल विकल्प"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टार्टअप विकल्प"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यक्रम गतिविधि"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट का प्रबंधन करें"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटरफेस"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना खाता"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलाव का"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य-प्रगति सुविधाएँ"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादन करना आपके प्रोफ़ाइल"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड बदलें"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गूगल ड्राइव को फिर से लिंक करें"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गूगल ड्राइव को लिंक करें"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक प्रोफ़ाइल"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह जानकारी सभी ने देखी है।"])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी अधिसूचना सेटिंग्स बदलें।"])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचना ध्वनि"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना ध्यान आकर्षित करने के लिए एक शांत ध्वनि बनाता है"])},
      "notification-mentions-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल उल्लेख/प्रत्यक्ष संदेश"])},
      "notification-mentions-only-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह केवल उल्लेख करता है और प्रत्यक्ष संदेश ध्वनि बनाएंगे और धकेल दिए जाएंगे।"])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर पुश नोटीफिकेशन"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऐप बंद होने पर भी पुश नोटिफिकेशन प्राप्त करें।"])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुफ्त में अपने खुद के सुंदर इमोजी अपलोड करें! इमोजी 3 एमबी या उससे कम होना चाहिए।"])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समर्थित फ़ाइल प्रकार हैं: पीएनजी, जेपीजी, जीआईएफ"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमोजी जोड़ें"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डेस्कटॉप ऐप स्टार्टअप विकल्प"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इन सेटिंग्स को संशोधित करने के लिए, आपको Nertivia डेस्कटॉप ऐप डाउनलोड करना होगा।"])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टार्टअप पर नर्टिविया खोलें"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नीचे दिए गए ऐप्स जोड़कर अपने द्वारा चलाए जा रहे प्रोग्राम या गेम साझा करें। जब कोई प्रोग्राम खोला जाता है तो यह आपकी कस्टम स्थिति को बदल देगा और प्रोग्राम बंद होने पर वापस वापस आ जाएगा।"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोग्राम गतिविधि सुविधा का उपयोग करने के लिए, आपको Nextiva डेस्कटॉप ऐप डाउनलोड करना होगा।"])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट उपयोगकर्ता बनाएं जो आपको अपने स्वयं के फैंसी बॉट बनाने के लिए हमारे एपीआई का उपयोग करने की अनुमति देगा।"])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को आसानी से बताने के लिए बॉट कमांड जोड़ें"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट बनाएँ"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमांड जोड़ें"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादन करना Bot"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादन करना Commands"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित लिंक बनाएं"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी आमंत्रित URL"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन को प्रबंधित करें"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन दिखाएं"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी टोकन"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन रीसेट करें"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट हटाएं"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप अपना खाता हटाने वाले हैं"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या हटा दिया जाएगा"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या डिलीट नहीं होगा"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके संदेश सार्वजनिक सर्वर को भेजे गए"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवश्यकताएं"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सर्वर को छोड़ें/हटाएं"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी बॉट हटाएं"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना My खाता"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा बदलने के बाद पुनः लोड की आवश्यकता हो सकती है। गिटहब रिपॉसिटरी में एक पुल रिक्वेस्ट जमा करके योगदान करें।"])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवादकों"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नर्टिविया की उपस्थिति को बदलें।"])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रंग की"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय प्रारूप"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्व निर्धारित विषय"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमोल्ड डार्क"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हैलोवीन 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्व-परिभाषित थीम"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम सीएसएस"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नेविगेशन पट्टी"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधूरा या प्रयोगात्मक सुविधाओं को सक्षम करें।"])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी कोई कार्य-प्रगति सुविधाएँ उपलब्ध नहीं हैं।"])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमतियां"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यवस्थापक"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी अनुमतियों को सक्षम करता है"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश भेजो"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर चैनलों को संदेश भेजें"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिकाएँ प्रबंधित करें"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिकाओं को अपडेट या हटाएं"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल प्रबंधित करें"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल को अपडेट या हटाएं"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लात मारना"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता को लात मारो"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लात मारना और उपयोगकर्ता को शामिल होने से प्रतिबंधित करें"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल प्रबंधित करें"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिकाएँ प्रबंधित करें"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित करें"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर दृश्यता"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमोजी का प्रबंधन करें"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिसूचना का प्रबंधन करें"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंधित उपयोगकर्ता"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को प्रबंधित करें"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर हटाएं"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी भूमिकाओं का प्रबंधन करें।"])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिफ़ॉल्ट भूमिका"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बॉट भूमिका"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई भूमिका बनाएं"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस भूमिका को किसी अन्य सदस्य को हटा नहीं दिया जा सकता है क्योंकि यह एक बॉट द्वारा बनाया गया था। इस भूमिका को हटाने के लिए बॉट निकालें।"])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस भूमिका को हटाया नहीं जा सकता है या अन-असाइन किया जा सकता है क्योंकि यह डिफ़ॉल्ट भूमिका है जो प्रत्येक सदस्य पर लागू होती है।"])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिका रंग चुनें"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिका का नाम"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समायोजन"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिका हटाएं"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिका हटाना…"])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भूमिका छिपाना"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य सर्वर सेटिंग्स"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने चैनलों को प्रबंधित करें।"])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएं चैनल"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल हटाना…"])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल का नाम"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह एक डिफ़ॉल्ट चैनल है। आप सामान्य पृष्ठ में डिफ़ॉल्ट चैनल बदल सकते हैं। डिफ़ॉल्ट चैनल मिटाए नहीं जा सकते।"])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया बनाओ"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश भेजो"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर सीमा (सेकंड)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ चैनल"])},
      "category-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एचटीएमएल चैनल (कार्य प्रगति पर है)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ से अनबैन सदस्य"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनबनिंग…"])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अप्रतिबंधित करें"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल एडमिन सभी के आमंत्रण देख सकते हैं।"])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित करें"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम आमंत्रण"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम लिंक सहेजें"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रित करें"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाने वाला"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोग"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज उपयोगकर्ता"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि करने के लिए सर्वर नाम टाइप करें"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाना…"])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिसूचना सेटिंग्स बदलें।"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ये सेटिंग्स केवल आप पर लागू होंगी और दूसरों को प्रभावित नहीं करेंगे।"])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं सक्षम करें"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्वनि म्यूट करें"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं बंद करें"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " टाइप कर रहा है…"])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " और ", _interpolate(_list(1)), " टाइप कर रहे हैं…"])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " और ", _interpolate(_list(2)), " टाइप कर रहे हैं…"])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " लोग टाइप कर रहे हैं…"])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मित्र"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाल ही"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाए गए नोट्स"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोस्त जोड़ें"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिसूचना"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कस्टम स्थिति जोड़ने के लिए क्लिक करें"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सारी जानकारी प्राप्त!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीधे संदेश"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्वेषण करना"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["धूर्तता"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उनके उपयोगकर्ता नाम और टैग को टाइप करके एक दोस्त जोड़ें।"])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ना…"])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर जोड़े"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक आमंत्रित कोड में प्रवेश करके एक सर्वर से जुड़ें।"])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना खुद का सर्वर बनाएं। अपने सर्वर का नामकरण करके शुरू करें।"])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ना"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सृजन करना"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफलाइन"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑनलाइन"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दूर"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यस्त"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेलने के लिए देख रहे हैं"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["सदस्य (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ऑफलाइन (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परस्पर मित्र"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बैज"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["में शामिल हुए"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निलंबित"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मित्र हटायें"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध स्वीकार करें"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध रद्द"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोस्त जोड़ें"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेसेज भेजें"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परस्पर सर्वर"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोड हो रहा है..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तैयार!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणित…"])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्यतन"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्यतन उपलब्ध है"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिलिपि"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनना"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर छोड़ दिया"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लात मार दी गई है"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["को बैन कर दिया गया है"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर में शामिल हो गया!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर से कनेक्ट नहीं कर सकते।"])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर सीमा विधा"])}
  }
}